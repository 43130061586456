<script>
    export let dataset_id;
</script>

<h3>
    {'\u2B50'} This dataset is machine-readable by MINT Data Transformation Service.
        <a target="_blank" href='https://data-trans.mint.isi.edu/pipeline/create?dcatId={dataset_id}'>
        View available transformations {'\u27A6'}
    </a>{'\u2B50'}

<!--    <a target="_blank" href='https://data-trans.mint.isi.edu/pipeline/create?dcatId={dataset_id}'>-->
<!--        <button>View available transformations {'\u27A6'}</button>-->
<!--    </a>-->
</h3>