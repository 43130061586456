// var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
import mapbox from 'mapbox-gl';
mapbox.accessToken = "__MAPBOXGL_ACCESS_TOKEN__";

// var map = new mapboxgl.Map({
//     container: 'YOUR_CONTAINER_ELEMENT_ID',
//     style: 'mapbox://styles/mapbox/streets-v11'
// });

const key = {};

export { mapbox, key };